import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import useSelector from '../redux/typedHooks';

interface Response {
  cityName: string | undefined;
  cityError: boolean;
  getCity: () => Promise<string | undefined>;
}

export const useFetchCity = (postalCode: string | undefined): Response => {
  const [cityName, setCityName] = useState<string | undefined>();
  const [cityError, setCityError] = useState<boolean>(false);
  const { apimBaseUrl, apimMembershipApi, apimContentHub } = useSelector((state) => state.application);

  const getCity = useCallback(async () => {
    if (postalCode?.length === 4) {
      try {
        const res = await axios.get<{ city: string }>(
          `${apimBaseUrl}/${apimMembershipApi}/address/city-name?postalCode=${postalCode}`,
          {
            headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
          },
        );
        setCityName(res.data.city);
        setCityError(false);
        return res.data.city;
      } catch (e) {
        setCityError(true);
        setCityName('UKJENT');
        return 'UKJENT';
      }
    }
    return 'UKJENT';
  }, [postalCode, apimBaseUrl, apimContentHub, apimMembershipApi]);

  useEffect(() => {
    getCity();

    return () => {};
  }, [postalCode, getCity]);

  return { cityName, cityError, getCity };
};
